<template>
  <div style="width:100%;background-color: #40475b;height: 100vh;">
    <transition name="slide-fade">
      <div class="emojiBox" v-show="showEmoji">
        <div v-for="emoji in emojiName" :key="emoji" @click="chooseEmoji(emoji)">
          <img :src="emojiUrl + emojiMap[emoji]" />
        </div>
      </div>
    </transition>
    <el-row>
      <el-col :span="24" style="padding: 10px 0;">
        <div style="height: 50px;display: flex;justify-content: space-between;padding:5px 20px;align-items: center;">

          <img src="../../assets/image/whimgnewLogo.png" class="logo" />
          <div>
            <el-button type="primary" size="small" @click="endPlay">退出</el-button>
            <!-- <img src="../../assets/img/avatar.png" class="avatar" /> -->
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div @mousedown="showEmoji=false" style="height: 250px;background-color: #000;">
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="playsinline"
            :options="playerOptions" @play="startPlay" @ended="endedPlay">
          </video-player>
        </div>
      </el-col>
    </el-row>
    <div style="height:calc(100% - 320px);color: #8a9099">
     <div class="tabs">
       <div :class="active==1?'panel active':'panel'" @click="active=1">讨论区</div>
       <div :class="active==2?'panel active':'panel'" @click="active=2">直播介绍</div>
     </div>
      <div class="content" v-show="active==1">
        <div style="height:calc(100% - 23px);overflow: auto;">
            <div class="tlq" @mousedown="showEmoji=false">
              <div style="">
                <div v-for="(item,index) in chats" :key="index" style="margin-bottom: 10px;">
                  <div style="display: flex;line-height:18px;padding:3px 12px;font-size: 14px;align-items: center;background-color:rgba(223,223,223,0.05);border-radius: 12px;width: fit-content;">
                    <div style="color:#a3edff ;margin-right: 12px;">{{item.userName}}：</div>
                   <div
                     style="background-color:rgba(223,223,223,0.05);color: #fff;word-break: break-all;">
                     <span v-html="messageProcessing(item.content)"></span>
                   </div>
                  </div>

                </div>
              </div>
        </div>
        <div style="border: 1px #606576 solid;width: 70%;border-radius: 16px;padding: 1px;position: absolute;bottom: 10px;">
          <el-input type="text" v-model="form.inputValue" placeholder="说点什么">
            <el-button slot="append" style="background-color: #006eff;color: #fff;" class="btn" @click="sendMsg">发送</el-button>
          </el-input>
        </div>
        </div>
      </div>
      <div class="content" v-show="active==2">
         <p v-html="resourceInfo.introduce"></p>
      </div>

    </div>
   <!-- <el-row style="display: none;">
      <el-col :span="24">

      </el-col>
    </el-row> -->

  </div>
</template>
<script>
  // eslint-disable-next-line no-unused-vars
  import jsCookie from "js-cookie";
  import {
    getYunTelecastLubo_api,
    saveTelecastTeacherHour_api,
    getLength_api,
    saveTelecastChat_api
  } from '@/api/live';
  import {
    emojiMap,
    emojiName,
    emojiUrl
  } from '@/utils/emojiMap';
  import {
    sessionGet
  } from "utils/local";
  import {
    dateFormat
  } from "utils/dateFormat";
  export default {
    name: 'Details',
    data: () => {
      return {
        active:1,
        showEmoji: false, //显示emoji表情
        chats: [

        ], //聊天信息
        form: {
          inputValue: ''
        },
        emojiMap,
        emojiName,
        emojiUrl,
        activeName: 'first',
        resourceInfo: '',
        enclosureList: [],
        token: sessionGet('tk'),
        Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        // 播放器配置
        playsinline: true,
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          controls: true, //是否显示控制条
          autoplay: true, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: 'video/mp4',
            src: '' // url地址
          }],
          poster: '', // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            // timeDivider: true, // 当前时间和持续时间的分隔符
            // durationDisplay: true, // 显示持续时间
            // remainingTimeDisplay: false, // 是否显示剩余时间功能
            // fullscreenToggle: true // 是否显示全屏按钮
            volumeControl: true
          }
        },
        timerId: '', //计时器id
        timer: 0, //计时器
        duration: '', //学时时长
        websocket: null,
        userInfo:''
      }
    },
    created() {
      this.userInfo = sessionGet('userInfo') ? sessionGet('userInfo') : JSON.parse(jsCookie.get('userInfo'))
      if ('WebSocket' in window) {
        this.websocket = new WebSocket('wss://www.wuhousmartedu.com/socket/webSocket');
      } else {
        alert('该浏览器不支持websocket');
      }
    },
    mounted() {
      this.websocket.onopen = () => {
        console.log('建立连接')
      }
      this.websocket.onclose = () => {
        console.log('连接关闭')
      }
      this.websocket.onmessage = (ev) => {
        if (this.isJson(ev.data)) {
          this.chats.push(JSON.parse(ev.data))
        }
      }
      getLength_api().then(res => {
        this.duration = res.data
      })
      this.resourceDetails();
      this.watchTabUnload();
    },
    methods: {
      //浏览器刷新或关闭
      watchTabUnload() {
        if (this.userInfo.role_name == 'teacher') {
          let that = this;
          window.onbeforeunload = function() {
            this.websocket.close();
            that.saveTelecastTeacherHour();
            return '关闭提示';
          }
        }
        console.log(222)
        //this.websocket.close();
      },
      // 获取资源详情
      async resourceDetails() {
        let telecastId = this.$route.query.telecastId
        const {
          data
        } = await getYunTelecastLubo_api({
          "telecastId": telecastId
        });
        this.playerOptions.sources[0].src = data.resourcesUrl;
        this.resourceInfo = data;
      },
      startPlay() {
        this.timer = 0;
        this.timerId = setInterval(() => {
          this.timer++
        }, 1000)
      },
      /* 视频结束播放*/
      endedPlay() {
        if (this.userInfo.role_name == 'teacher') {
          this.saveTelecastTeacherHour();
        }
        clearInterval(this.timerId);
      },
      /* 点击结束播放*/
      endPlay() {
        if (this.userInfo.role_name == 'teacher') {
          let str = '是否结束观看？';
          if (this.timer - this.duration < 0) {
            str = '您的观看时长不足一个学时，是否退出直播间？'
          }
          this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {  console.log(232355)

            //保存计时
            this.saveTelecastTeacherHour().then(res=>{
              if(res.success){
                //取消计时器
                clearInterval(this.timerId);
                 this.$router.go(-1);
                this.websocket.close();

                console.log(2323)
              }
            });

            //this.playerOptions.sources[0].src = [];
           // this.playerOptions.sources[0].src = this.resourceInfo.resourcesUrl;

            /* 跳转到个人中心页面*/
            // if (this.userInfo.role_name === 'teacher') {
            //   this.$router.push('/mineTeacher')
            // } else {
            //   this.$router.push('/mine')
            // }
          }).catch(() => {
            return
          });
        } else {
          this.websocket.close();
          this.playerOptions.sources[0].src = [];
          this.playerOptions.sources[0].src = this.resourceInfo.resourcesUrl;
          /* 跳转到个人中心页面*/
           this.$router.go(-1);
          // if (this.userInfo.role_name === 'teacher') {
          //   this.$router.push('/mineTeacher')
          // } else {
          //   this.$router.push('/mine')
          // }
        }
      },
      /* 视频计时*/
      saveTelecastTeacherHour() {
        if (this.userInfo.role_name == 'teacher') {
          saveTelecastTeacherHour_api({
            type: 2,
            resourcesId: this.resourceInfo.id,
            length: this.timer,
            date: dateFormat('yyyy-MM-dd hh:mm:ss'),
            userId: sessionGet('userInfo').user_id
          });
        }
      },
      chooseEmoji(emoji) {
        this.form.inputValue += emoji;
      },
      //处理聊天emoji显示
      messageProcessing(strCont) {
        var str = strCont.match(/\[(.+?)\]/g); //regex2 = /\[(.+?)\]/g; // [] 中括号；regex3 = /\{(.+?)\}/g;  // {} 花括号，大括号
        if (str) {
          str.map(val => {
            var reg = new RegExp("\\" + val, "g");
            var strimg = '<img width="30px" align="absmiddle" src=' + emojiUrl + emojiMap[val] + '>';
            strCont = strCont.replace(reg, strimg);
          })
        }
        return strCont;
      },
      //判断是否为json
      isJson(str) {
        if (isNaN(Number(str))) {
          try {
            JSON.parse(str);
            return true;
          } catch (e) {
            return false;
          }
        }
        return false;
      },
      //发送聊天
      sendMsg() {
        let data = {
          userName: this.userInfo.nick_name,
          content: this.form.inputValue,
          createTime: dateFormat('yyyy-MM-dd hh:mm:ss'),
          telecastId: this.$route.query.telecastId,
          createUserId: this.userInfo.user_id
        }
        if (this.form.inputValue.trim().length > 0) {
          //添加记录
          saveTelecastChat_api(data).then(res => {
            if (res.success) {
              // this.chats.push(data);
              this.websocket.send(JSON.stringify(data));
              this.form.inputValue = '';
            } else {
              this.$message.error('消息发送失败');
            }
          })

        }
      }
    }
  }
</script>
<style scoped>
  ::deep.el-input__inner{
    background-color: #40475b;
   border: 0px;
   color: #8a9099;
  border-radius: 16px;
   height: 32px;
  }

  ::deep.el-input-group__append{
    background-color: #40475b;
    border: 1px #40475b solid;
  }
  .btn{
    padding: 2px 4px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 24px;
    width: 50px;
  }
  .tabs{
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 7px;
  }
  .panel{
    padding: 10px 0;
  }
  .content{
    padding: 20px 25px 25px;
    font-size: 16px;
    word-wrap: break-word;
    overflow: auto;
    height: calc(100% - 53px);
  }
  .panel.active{
    color: #fff;
    border-bottom: 3px #fff solid;
  }
  .slide-fade-enter-active {
    transition: all .2s ease;
  }

  .slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(5px);
    opacity: 0;
  }

  .emojiBox {
    border: 1px #000 solid;
    height: 182px;
    overflow: scroll;
    width: 400px;
    position: absolute;
    padding: 10px;
    right: 12%;
    bottom: 200px;
    z-index: 999;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .emojiBox img {
    width: 30px;
    cursor: pointer;
  }

  ::deep.el-tabs__nav-scroll {


    height: 60px;
    line-height: 60px;


  }

  ::deep.el-tabs__nav-wrap {
    display: flex;
    justify-content: center;
  }

  ::deep.el-tabs__item {
    color: #8a9099;
    font-size: 18px;
    font-weight: 500;
  }

  ::deep.el-tabs__nav-wrap::after {
    background-color: #454c5e;
  }

  ::deep.el-tabs__item:hover {
    color: #8a9099;
  }

  ::deep .el-tabs__item.is-active {
    color: #fff;
  }

  ::deep.el-tabs {
    background-color: #383f54;
  }

  ::deep.el-tabs__active-bar {
    background-color: #fff;
  }

  .logo {
    margin-right: 10px;
    height: 80%;
    vertical-align: bottom;
    border-radius: 5px;
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
  }

  .tlq {
    height: 10px;
    display: flex;
    flex-direction: column;
  }
</style>
